import React from "react";

const Team = () => {
  return (
    <div
      className="h-screen flex flex-col  gap-10 items-center w-[75%] md:w-[60%]"
      style={{ overflow: "auto" }} // Added overflow: auto here
    >
      <div className="flex flex-row mx-auto gap-6 w-full px-5">
        <div className="flex-1 md:flex-[0.5] relative mt-[80px] md:mt-[250px]">
          <img
            className="w-full h-[380px] lg:h-[450px] object-cover"
            src="./images/josh.jpeg"
            alt="josh"
          />
          <div
            className="absolute text-white top-[40%] right-0 translate-x-[50%] px-2 py-1 rounded-2xl"
            style={{
              backgroundColor: "rgba(16, 18, 27, 0.4)",
              backdropFilter: "blur(20px)",
            }}
          >
            <div>JOSH</div>
            <div>Co-</div>
            <div>Founder</div>
          </div>
        </div>
        <div className="flex-[0] md:flex-[0.5]">
          {/* Another content here */}
        </div>
      </div>

      <div className="flex flex-row mx-auto gap-6 w-full">
        <div className="flex-[0] md:flex-[0.5]">
          {/* Another content here */}
        </div>
        <div className="mr-11 flex-1 md:flex-[0.5] relative">
          <img
            className="w-full h-[380px] lg:h-[450px] object-cover"
            src="./images/yitz.jpg"
            alt="yitz"
          />
          <div
            className="absolute text-white top-[40%] right-0 translate-x-[50%] px-2 py-1 rounded-2xl"
            style={{
              backgroundColor: "rgba(16, 18, 27, 0.4)",
              backdropFilter: "blur(20px)",
            }}
          >
            <div>YITZ</div>
            <div>Dev</div>
            <div>Manager</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
