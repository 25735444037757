import React from 'react'
import ServiceCards from '../components/ServiceCards'

const Services = () => {
  return (
    <div className="w-[70%] lg:w-1/2 h-[80vh] lg:h-full text-white bg-black bg-opacity-40 p-3 lg:p-6 rounded-xl overflow-y-scroll">
        <p className='text-[14px] lg:text-[16px] mb-6'>Tripp3 Labs delivers institutional quality Web3 engagement solutions. Together, we will create, develop, and deploy NFT projects custom designed for your purpose. We are a completely vertical team-  we'll handle the design marketing and post-project management. Our unique infrastructure allows us to serve as your in-house Web3 design and development team. Tripp3 services include asset tokenization and distribution management. Welcome to the Future.</p>
        <ServiceCards />
    </div>
  )
}

export default Services