import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar'
import Home from './pages/Home';
import Team from './pages/Team';
import Work from './pages/Work';
import Services from './pages/Services';
import Footer from './components/Footer';

function App() {

  return (
    <div className="h-screen flex items-center justify-center relative">
      <Navbar />
      <Sidebar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
        <Route path="/work" element={<Work />} />
        <Route path="/services" element={<Services />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
