import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="absolute top-0 left-0 pl-16 lg:pl-24 pt-8 lg:pt-12 w-full bg-transparent text-white flex justify-between items-center p-4">
      <div className="flex space-x-8">
        <Link to="/" className="cursor-pointer uppercase text-[14px] font-semibold">Home</Link>
        <Link to="/work" className="cursor-pointer uppercase text-[14px] font-semibold">Work</Link>
      </div>
    </div>
  );
};

export default Navbar;
