import React from 'react';

const Footer = () => {
  return (
    <div className="absolute bottom-0 right-0 m-4 text-white">
      <p className='text-[14px]'>&copy; Tripp3Labs</p>
    </div>
  );
};

export default Footer;
