import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="absolute top-0 left-0 h-screen w-20 pt-20 text-white flex flex-col justify-between items-center py-4">
      <div className="flex flex-col items-center space-y-8">
        <Link
          to="/team"
          className="vertical-text cursor-pointer py-1 uppercase text-[15px] font-semibold"
        >
          Team
        </Link>
        {/* <div className="vertical-text cursor-pointer py-1 uppercase text-[15px] font-semibold">
          Reach
        </div> */}
        <Link
          to="/services"
          className="vertical-text cursor-pointer py-1 uppercase text-[15px] font-semibold"
        >
          Services
        </Link>
      </div>
      <div className="flex flex-col items-center space-y-8">
        <div className="vertical-text cursor-pointer py-1 uppercase text-[15px] font-semibold">
          Poll
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
