import React from "react";

const Work = () => {
  // List of projects, you might want to fetch these from a server or context in a real app
  const projects = [
    { name: "PMF NFT Club", url: "https://mcaanft.org/" },
    { name: "trippy.vc", url: "https://trippy.vc/" },
    { name: "investalog", url: "https://mcaanft.org/" },
    { name: "DTS", url: "https://www.dtstennis.io/" },
    { name: "trippyhaseedsclub", url: "https://www.haseeds.com/" },
    {
      name: "cryptocannabisclub",
      url: "https://factmata.com/",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "rgb(16 18 27 / 40%)",
        border: "1px solid rgba(113 119 144 / 25%)",
      }}
      // className="rounded-xl "
      className="rounded-xl w-[70%] sm:w-auto"
    >
      <h2 className="text-[#b7c3c2c7] text-lg font-medium py-2 px-8">
        Recent Projects
      </h2>

      <ul
        style={{ borderTop: "1px solid rgba(113 119 144 / 25%)" }}
        className="space-y-7 px-8 py-5"
      >
        {projects.map((project) => (
          <li key={project.name}>
            <a
              href={project.url}
              target="_blank" // Opens link in a new tab
              rel="noopener noreferrer" // Security measure for opening links in new tabs
              className="font-bold text-lg md:text-2xl hover:underline block overflow-hidden"
              style={{
                background: "linear-gradient(to right, #cc99ef, #47ebd4)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {project.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Work;
