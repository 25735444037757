import React from 'react';

const servicesData = [
    {
        domain: 'Conceptualize',
        services: ['Branding', 'Digital Strategy', 'Accounting Strategy', 'UI/UX'],
    },
    {
        domain: 'Development',
        services: ['Unique Web Design', 'React 18/Next JS', 'Cross platform/browser', 'Version Control'],
    },
    {
        domain: 'Hosting/Management',
        services: ['Vercel', 'Heroku', 'Maintenance', 'SEO Standards'],
    },
    {
        domain: 'Marketing',
        services: ['Advertising Campaign', 'Marketing & Paid Media', 'Social Media Content', 'Creative & Photography'],
    },
];  

const ServiceCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {servicesData.map((item, index) => (
        <div key={index} className="bg-[#9297B3] bg-opacity-20 shadow-lg rounded-lg">
          <h3 className="text-[18px] lg:text-xl text-[#fdfdfd] mb-2 border-b p-4">{item.domain}</h3>
          <ul className="list-disc list-inside p-6 pb-12">
            {item.services.map((service, idx) => (
              <p key={idx} className="text-white">{service}</p>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ServiceCards;
